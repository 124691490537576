





























import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import Site from "@/models/Site";
import BopModelService from "@/services/BopModelService";

import { Guid } from "guid-typescript";

@Component({
  components: {
    Modal: () => import("@/components/Shared/Modal.vue"),
  },
})
export default class BopModal extends Vue {
  @Prop({ default: false }) public show!: boolean;
  @Prop({ default: undefined }) public props!: any;

  private bops: Array<Site> = [];
  private checkBops: Array<string> = [];

  public mounted() {
    BopModelService.getBopModels(this.$i18n.locale).then((response) => {
      this.bops = response.data;
    });
  }

  @Watch("show")
  onShowChange(value: boolean, oldValue: boolean) {
    if (!value) this.checkBops = [];
    else {
      this.props.rowData.entreprise.bopModels.forEach((el: any) => {
        this.checkBops.push(el.bopModel.id);
      });
    }
  }

  public validate() {
    // console.log(this.checkBops)
    // console.log(this.bops)
    this.props.rowData.entreprise.bopModels = this.checkBops.map((guid) => {
      const temp: any = this.bops.find((bop: any) => bop.bopModel.id == guid);
      if (temp !== undefined) {
        return temp;
      }
    });
    this.$emit("validate", this.props.rowData);
  }
}
